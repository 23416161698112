import auth from '@udacity/ureact-hoth';

import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { publicEnv } from '~/features/environment/public';
import { getUserIdFromJwt } from '~/features/user/utils/get-user-id-from-jwt';

import { getDefaultAnalyticProperties, getGeoAnalyticsProperties } from './utils/get-analytics-properties';
import { getContext } from './utils/get-context';
import { getSegmentUserId } from './utils/get-segment-user-id';
import { SEGMENT_URL, UDACITY_SEGMENT_PROXY_URL } from './constants';
import { AnalyticsEvent } from './types';

const segmentWriteKey = publicEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

const isEventLoggerOn =
  publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && publicEnv.NEXT_PUBLIC_DEBUG_ANALYTICS;

export async function httpTrackEvent(
  analyticsEvent: AnalyticsEvent,
  { sendToSalesforce = false }: { sendToSalesforce?: boolean } = {}
): Promise<{ success: boolean } | undefined> {
  if (typeof window === 'undefined') return;

  if (isEventLoggerOn) {
    console.log(`${analyticsEvent?.type}:`, analyticsEvent);
  }

  const { name, additionalAttributes, ...remainingEventProperties } = analyticsEvent;
  const anonymousId = getAnonymousIdCookie();
  const context = getContext();
  const jwt = auth.getJWT();
  const userId = jwt ? getUserIdFromJwt(jwt) : getSegmentUserId();

  const properties = {
    ...getDefaultAnalyticProperties(),
    ...getGeoAnalyticsProperties(),
    ...remainingEventProperties,
    ...additionalAttributes,
  };

  const integrations = {
    Salesforce: sendToSalesforce,
  };

  const payload = {
    event: name,
    userId: userId ?? null,
    anonymousId,
    integrations,
    properties,
    context,
    writeKey: segmentWriteKey,
    type: 'track',
  };

  const baseUrl = publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' ? UDACITY_SEGMENT_PROXY_URL : SEGMENT_URL;

  const response = await fetch(`${baseUrl}/track`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${segmentWriteKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw Error(`Track request failed with code: ${response.status}`);
  }

  return await response.json();
}
