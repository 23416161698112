export function getContext() {
  const url = new URL(window.location.href);

  const context = {
    page: {
      path: url.pathname,
      search: url.search,
      url: url.href,
      title: document.title,
    },
  };

  return context;
}
