import { z } from 'zod';

import { gql } from '~/utils/gql';

export const seriesSchema = z.object({
  id: z.string(),
  cohortId: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  firstMeeting: z
    .object({
      totalCount: z.number(),
      meetings: z.array(
        z.object({
          startAt: z.string(),
          endAt: z.string(),
          locales: z.array(z.string()),
        })
      ),
    })
    .optional(),
  lastMeeting: z
    .object({
      meetings: z.array(
        z.object({
          startAt: z.string(),
          endAt: z.string(),
          locales: z.array(z.string()),
        })
      ),
    })
    .optional(),
  assignmentType: z.string().nullable().optional(),
});

export const seriesResponseSchema = z.object({
  data: z.object({
    series: z.object({
      series: z.array(seriesSchema),
    }),
  }),
});

export type Series = z.infer<typeof seriesSchema>;
export type SeriesResponse = z.infer<typeof seriesResponseSchema>;

export const fetchConnectCohortSeriesQuery = gql`
  query FetchConnectCohortSeries($cohortId: UUID!, $userKey: String!) {
    series(
      input: {
        cohortId: $cohortId
        availability: { availableToUsers: true, availableToUserKeys: [$userKey] }
        sortBy: NAME
        sortOrder: ASC
        paginationParams: { limit: 500, offset: 0 }
      }
    ) {
      series {
        id
        cohortId
        name
        description
        firstMeeting: meetings(input: { sortBy: START_AT, sortOrder: ASC, paginationParams: { limit: 1, offset: 0 } }) {
          meetings {
            startAt
            endAt
            locales
          }
          totalCount
        }
        lastMeeting: meetings(input: { sortBy: START_AT, sortOrder: DESC, paginationParams: { limit: 1, offset: 0 } }) {
          meetings {
            startAt
            endAt
            locales
          }
        }
      }
    }
  }
`;
