export enum PageCategory {
  Catalog = 'Catalog',
  Collection = 'Collection',
  Checkout = 'Checkout',
  PaidProgram = 'Paid Program',
  FreeProgram = 'Free Program',
  Home = 'Home',
  Uncategorized = 'Uncategorized',
  School = 'School',
  PersonalizedDiscount = 'Personalized Discount',
  WelcomeFlow = 'Welcome Flow',
  GetStarted = 'Get Started',
  Plans = 'Plans',
  Legal = 'Legal',
  Experience = 'Experience',
  Scholarship = 'Scholarship',
  CaseStudy = 'Case Study',
  EnterpriseEbook = 'Enterprise Ebook',
  EnterpriseInfographic = 'Enterprise Infographic',
  EnterpriseReport = 'Enterprise Report',
  EnterpriseWebinar = 'Enterprise Webinar',
  EnterpriseVideo = 'Enterprise Video',
  EnterpriseResourceCenter = 'Enterprise Resource Center',
  Skills = 'Skills',
}

export const UDACITY_SEGMENT_PROXY_URL = 'https://sgmt.udacity.com/api/v1';
export const SEGMENT_URL = 'https://api.segment.io/v1';
