import { useEffect, useState } from 'react';

export function useClientRender() {
  const [clientReady, setClientReady] = useState(false);

  useEffect(() => {
    setClientReady(true);
  }, []);

  return clientReady;
}
