import { z } from 'zod';

import { gql } from '~/utils/gql';

import { seriesSchema } from './series';

export const connectCohortSchema = z.object({
  id: z.string(),
  name: z.string(),
  showLeaderboard: z.boolean(),
  maxSeriesSwitches: z.number(),
  relevantEnrollments: z.array(z.object({ programKey: z.string() })),
});

export const myConnectCohortsResponseSchema = z.object({
  data: z.object({
    myConnectData: z.object({
      myCohorts: z.object({
        cohorts: z.array(
          z.object({
            cohort: connectCohortSchema,
            mySeries: z.object({
              series: z.array(seriesSchema),
            }),
            availableSeries: z.object({
              totalCount: z.number(),
            }),
            seriesSwitchesLeft: z.number(),
          })
        ),
      }),
    }),
  }),
});

export const activeConnectCohortCountsResponseSchema = z.object({
  data: z
    .object({
      myConnectData: z.object({
        myCohorts: z.object({
          totalCount: z.number(),
        }),
      }),
    })
    .nullable(),
});

export type ConnectCohort = z.infer<typeof connectCohortSchema>;
export type MyConnectCohortsResponse = z.infer<typeof myConnectCohortsResponseSchema>;
export type ActiveConnectCohortCountsResponse = z.infer<typeof activeConnectCohortCountsResponseSchema>;

export const fetchMyConnectCohortsQuery = gql`
  query FetchMyConnectCohorts($cohortId: UUID, $userKey: String!) {
    myConnectData(input: { userKey: $userKey }) {
      myCohorts(input: { id: $cohortId }) {
        cohorts {
          cohort {
            id
            name
            showLeaderboard
            maxSeriesSwitches
            relevantEnrollments {
              programKey
            }
          }
          seriesSwitchesLeft
          mySeries(input: { assignmentType: SELF }) {
            series {
              id
              cohortId
              name
              description
              assignmentType
              firstMeeting: meetings(
                input: { sortBy: START_AT, sortOrder: ASC, paginationParams: { limit: 1, offset: 0 } }
              ) {
                meetings {
                  startAt
                  endAt
                  locales
                }
                totalCount
              }
              lastMeeting: meetings(
                input: { sortBy: START_AT, sortOrder: DESC, paginationParams: { limit: 1, offset: 0 } }
              ) {
                meetings {
                  startAt
                  endAt
                  locales
                }
              }
            }
          }
          availableSeries(input: { paginationParams: { limit: 0, offset: 0 } }) {
            totalCount
          }
        }
      }
    }
  }
`;

export const fetchActiveConnectCohortCountQuery = gql`
  query FetchActiveConnectCohortCounts($userKey: String!) {
    myConnectData(input: { userKey: $userKey }) {
      myCohorts(input: { status: ACTIVE, paginationParams: { limit: 0, offset: 0 } }) {
        totalCount
      }
    }
  }
`;
