export function getSegmentUserId() {
  if (typeof window === 'undefined') {
    return null;
  }

  const userId = localStorage.getItem('ajs_user_id')?.replaceAll('"', '');

  // Segment can set a user id of "null" in local storage. This prevents returning the string "null" instead of the value null
  if (userId === 'null') {
    return null;
  }

  return userId;
}
