import auth from '@udacity/ureact-hoth';

import { httpTrackEvent } from '~/features/analytics/http-track-event';
import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { publicEnv } from '~/features/environment/public';

import { getGeoAnalyticsProperties } from '../analytics/utils/get-analytics-properties';
import { getActiveSubscriptionCookie } from '../cookies/utils/get-active-subscription-cookie';

/**
 * Fetches the variant of an experiment for a user from Amplitude.
 */
export async function getExperimentVariantOnClient({
  flagKey,
  userProperties,
}: {
  flagKey: string;
  userProperties?: any;
}): Promise<string | null | undefined> {
  const anonymousId = getAnonymousIdCookie();
  const userId = auth.getCurrentUserId();
  const forcedVariation = getForcedVariation();

  if (forcedVariation) {
    return forcedVariation;
  }

  const params = new URLSearchParams({
    flag_key: flagKey,
  });

  const geoProperties = getGeoAnalyticsProperties();

  const city = geoProperties.city;
  const regionCode = geoProperties.regionCode;
  const countryCode = geoProperties.countryCode;
  const hasActiveSubscription = getActiveSubscriptionCookie();
  const isAuthenticated = auth.isAuthenticated();

  const defaultUserProperties = {
    city,
    region_code: regionCode,
    country_code: countryCode,
    authenticated: isAuthenticated,
    active_subscription: hasActiveSubscription,
  };
  const combinedUserProperties = { ...defaultUserProperties, ...userProperties };

  if (anonymousId) params.append('device_id', anonymousId);
  if (userId) params.append('user_id', userId);
  params.append('context', JSON.stringify({ user_properties: combinedUserProperties }));

  try {
    const response = await fetch(`https://api.lab.amplitude.com/v1/vardata?${params}`, {
      headers: {
        Authorization: publicEnv.NEXT_PUBLIC_AMPLITUDE_CLIENT_KEY,
      },
    });

    if (!response.ok) {
      console.error(`Not OK returned from amplitude: ${response.status}`);
      return null;
    }

    const data: any = await response.json();

    const variationKey: string | null = data?.[flagKey]?.key ?? null;

    httpTrackEvent({
      name: 'Experiment Viewed V1',
      type: 'track',
      label: 'experiment_viewed',
      additionalAttributes: {
        experiment_key: flagKey,
        variation_key: variationKey,
        app_key: 'website',
      },
    });

    return variationKey;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function getForcedVariation() {
  if (!window) return null;

  const { searchParams } = new URL(window.location.href);
  const forcedVariation = searchParams.get('forcedVariation');

  return forcedVariation;
}

/**
 * Removes the experiment root from the URL pathname.
 */
export function removeExperimentRootFromUrl(url: URL): URL {
  if (!url.pathname.startsWith('/test/')) {
    return url;
  }
  // Example: '/test/albus/variant_a'
  const parts = url.pathname.split('/').slice(4);
  url.pathname = `/${parts.join('/')}`;
  return url;
}
