import { NextSeoProps } from 'next-seo';

import { ProgramContent } from '~/features/program/models/program-content';
import { ProgramMetadata } from '~/features/program/models/program-metadata';

import { Seo } from '../models/page';

function maybeAppendUdacity(title?: string | null): string | undefined {
  if (title) {
    if (title.trim().endsWith('| Udacity')) {
      return title;
    } else {
      return `${title.trim()} | Udacity`;
    }
  }
}

function transformOpenGraphSeo(
  seo: Seo,
  programContent?: ProgramContent,
  programMetadata?: ProgramMetadata
): NextSeoProps['openGraph'] {
  // Use programContent.openGraphTitle > programContent.seoTitle > seo.openGraphTitle > seo.title
  const title: string =
    maybeAppendUdacity(programContent?.openGraphTitle) ||
    maybeAppendUdacity(programContent?.seoTitle) ||
    seo.openGraphTitle ||
    seo.title;
  // Use programContent.openGraphDescription > programContent.seoDescription > seo.openGraphDescription > seo.description
  const description: string = programContent?.openGraphDescription || seo.openGraphDescription || seo.description;
  return {
    title,
    description,
    images: seo.openGraphImages?.map((image) => ({
      url: transformImageSrc(
        programMetadata?.rootProgram?.catalogImageUrl ? programMetadata?.rootProgram?.catalogImageUrl : image.asset.url
      ),
    })),
    type: seo.openGraphType ?? 'website',
    article: {
      authors: seo.openGraphArticleAuthors ?? undefined,
      section: seo.openGraphArticleSection ?? undefined,
      tags: seo.openGraphArticleTags ?? undefined,
      publishedTime: seo.openGraphArticlePublishedTime ?? undefined,
      modifiedTime: seo.openGraphArticleModifiedTime ?? undefined,
      expirationTime: seo.openGraphArticleExpirationTime ?? undefined,
    },
  };
}

export function transformSeoToNextSeo(
  seo: Seo,
  programContent?: ProgramContent,
  programMetadata?: ProgramMetadata
): NextSeoProps {
  return {
    title: programContent?.seoTitle ? maybeAppendUdacity(programContent.seoTitle) : seo.title,
    description: programContent?.seoDescription ? programContent.seoDescription : seo.description,
    noindex: seo.noindex ?? undefined,
    nofollow: seo.nofollow ?? undefined,
    canonical: seo.canonicalUrl ?? undefined,
    openGraph: transformOpenGraphSeo(seo, programContent, programMetadata),
  };
}

function transformImageSrc(src: string) {
  const url = new URL(src);
  url.searchParams.set('fit', 'crop');
  url.searchParams.set('w', '1200');
  url.searchParams.set('h', '630');
  return url.toString();
}
